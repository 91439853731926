import { useState, useRef, useEffect } from "react";
import { ArSdk } from "tencentcloud-webar";
import sha256 from "sha256";

import "./App.css";

/** ----- 鉴权配置 ----- */

/**
 * 腾讯云账号 APPID
 *
 * 进入[腾讯云账号中心](https://console.cloud.tencent.com/developer) 即可查看 APPID
 */
const APPID = "1303869159"; // '您的appid';

/**
 * Web LicenseKey
 *
 * 登录音视频终端 SDK 控制台的[Web License 管理](https://console.cloud.tencent.com/vcube/web)，创建项目即可获得 LicenseKey
 */
const LICENSE_KEY = "6f1cd038e4b8143f4a886c9c91321ae2"; // '您的licenseKey';

/**
 * 计算签名用的密钥 Token
 *
 * 注意：此处仅用于 DEMO 调试，正式环境中请将 Token 保管在服务端，签名方法迁移到服务端实现，通过接口提供，前端调用拉取签名，参考
 * [签名方法](https://https://cloud.tencent.com/document/product/616/71370#.E7.AD.BE.E5.90.8D.E6.96.B9.E6.B3.95)
 */
const token = "c84c4b821de03bfca12b77681bd9f9c8"; // '您的token';

/**
 * 获取签名方法
 *
 * 注意：此处仅用于 DEMO 调试，正式环境中请将 Token 保管在服务端，签名方法迁移到服务端实现，通过接口提供，前端调用拉取签名，参考
 * [签名方法](https://https://cloud.tencent.com/document/product/616/71370#.E7.AD.BE.E5.90.8D.E6.96.B9.E6.B3.95)
 *
 * 如：
 * async function () {
 *  return fetch('http://xxx.com/get-ar-sign').then(res => res.json());
 * };
 */
const getSignature = function () {
  const timestamp = Math.round(new Date().getTime() / 1000);
  const signature = sha256(timestamp + token + APPID + timestamp).toUpperCase();
  return { signature, timestamp };
};

function App() {
  const videoRef = useRef(null); // Original video feed
  const overlayRef = useRef(null); // Overlay image
  const canvasRef = useRef(null); // Canvas to combine video and overlay

  const [isReady, setIsReady] = useState(false);
  const [text, setText] = useState("preparing");
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const makeups = [];
  const filters = [];

  // Function to update height
  const updateHeight = () => {
    setWindowHeight(window.innerHeight);
  };

  // Update height on component mount and resize
  useEffect(() => {
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const ar = new ArSdk({
    module: {
      // 0.2.0版本新增
      beautify: true, // 是否启用美颜模块，启用后可以使用美颜、美妆、贴纸等功能
      segmentation: true, // 是否启用人像分割模块，启用后可以使用背景功能
      segmentationLevel: 2, // 1.0.19 及以上版本开始支持切换背景分割模型
    },
    auth: {
      authFunc: getSignature,
      appId: APPID,
      licenseKey: LICENSE_KEY,
    }, // 鉴权参数
    mirror: true, // 1.0.19 新增，输入流也可以设置镜像效果
    beautify: {
      whiten: 0.6, // 美白 0-1
      dermabrasion: 1, // 磨皮 0-1
      lift: 0.2, // 瘦脸 0-1
      shave: 0.2, // 削脸 0-1
      eye: 0.2, // 大眼 0-1
      chin: 0.2, // 下巴 0-1
    },
  });

  // 鉴权完成
  ar.on("created", () => {
    // 获取内置贴纸，美妆
    ar.getEffectList({
      Type: "Preset",
    })
      .then((res) => {
        const list = res.map((item) => ({
          name: item.Name,
          id: item.EffectId,
          cover: item.CoverUrl,
          url: item.Url,
          label: item.Label,
          type: item.PresetType,
        }));
        makeups.push(...list.filter((item) => item.label.indexOf("美妆") >= 0));
      })
      .catch((e) => {
        console.log(e);
      });

    // 获取内置滤镜
    ar.getCommonFilter()
      .then((res) => {
        const list = res.map((item) => ({
          name: item.Name,
          id: item.EffectId,
          cover: item.CoverUrl,
          url: item.Url,
          label: item.Label,
          type: item.PresetType,
        }));
        filters.push(...list);
      })
      .catch((e) => {
        console.log(e);
      });
  });

  ar.on("resourceReady", () => {
    if (isReady) return;

    setText("start");
  });

  ar.on("ready", async () => {
    if (!videoRef.current) return;

    setText("ready");

    const mediaStream = await ar.getOutput();
    videoRef.current.srcObject = mediaStream;
    videoRef.current.play();

    ar.setBackground({
      type: "image", // 图片背景
      src: "https://resources.moriverse.xyz/tf/background.jpeg",
    });

    // 设置美妆及贴纸
    if (makeups) {
      ar.setEffect([
        {
          id: makeups[0].id,
          intensity: 1,
          filterIntensity: 0, // 单独设置滤镜强度
        },
      ]);
    }

    // Start drawing video and overlay to canvas
    combineToCanvas();
  });

  // Combine video feed and overlay on canvas
  const combineToCanvas = () => {
    const video = videoRef.current;
    const overlay = overlayRef.current;
    const canvas = canvasRef.current;

    console.log("Overlay image:", overlay);

    if (!canvas || !video || !overlay) return;

    const ctx = canvas.getContext("2d");

    // Set canvas size to match video
    const videoTrack = video.srcObject.getVideoTracks()[0];
    const settings = videoTrack.getSettings();
    canvas.width = settings.width;
    canvas.height = settings.height;

    // Continuously draw video and overlay onto canvas
    const drawFrame = () => {
      if (video.readyState >= 2) {
        // Draw video frame
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Draw overlay image
        ctx.drawImage(overlay, 0, 0, canvas.width, canvas.height);
      }
      requestAnimationFrame(drawFrame); // Loop to keep drawing
    };
    drawFrame();
  };

  const startCamera = async () => {
    if (text === "ready") return;
    if (!videoRef.current) return;
    try {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const aspectRatio = Math.max(height, width) / Math.min(height, width);
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: "user",
          aspectRatio: 1, //aspectRatio,
        }, // 'user' for front camera, 'environment' for back camera
      });

      ar.initCore({
        input: stream,
      });

      setIsReady(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
      alert("Please allow camera access to use this feature.");
    }
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: `${windowHeight}px`,
        background: "black",
        overflow: "hidden",
      }}
    >
      <video
        ref={videoRef}
        style={{
          width: "100%",
          height: "100%",
          display: "none",
        }}
        autoPlay
        muted
        playsInline
      ></video>

      {/* Overlay Image */}
      <img
        ref={overlayRef}
        src="https://resources.moriverse.xyz/tf/overlay.png" // Replace with your overlay image URL
        alt="Overlay"
        style={{
          display: "none", // Hide the overlay; it will only appear on canvas
        }}
      />

      {/* Canvas for Combining Video and Overlay */}
      <canvas
        ref={canvasRef}
        style={{
          width: "100%",
          height: "auto",
        }}
      ></canvas>

      <button
        onClick={startCamera}
        style={{
          position: "absolute",
          top: "75%",
          left: "50%",
          transform: "translate(-50%, -50%)", // Center the button
          padding: "10px 20px",
          fontSize: "18px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          color: "black",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          zIndex: 1, // Ensure the button is above the video
        }}
      >
        {text}
      </button>
    </div>
  );
}

export default App;
